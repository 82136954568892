import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

export default function UCard(props) {    
  return (
    <Card sx={{ minWidth: 200 ,textAlign: 'center', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        padding: 2}}>
        <CardMedia sx={{minHeight: 200, width: '100%'  }} image={props.image} title={props.title}/>
      <CardContent >
        <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 20 ,fontFamily:'sans-serif' }}>{props.title}</Typography>        
        <Typography gutterTop >{props.content}</Typography>        
      </CardContent>
      <CardActions><Button variant="contained">OPEN</Button></CardActions>
    </Card>
  );
}