import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';

export default function UCardAdmin(props) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {setOpen(true);};
    const handleClose = () => {setOpen(false)};

  return (
    <Card sx={{ minWidth: 200 ,textAlign: 'center',borderStyle:'double',borderWidth:'10px', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        padding: 2}}>
        <CardMedia sx={{height: 100, width: '100%'  }} image={props.image}/>
      <CardContent >
      </CardContent>
    <CardActions>
        <Button variant="contained" onClick={handleClickOpen}>CLICK HERE FOR MORE INFORMATION</Button>
        <Dialog sx={{textAlign:'center'}} onClose={handleClose} open={open}>
        <DialogTitle><Button variant="contained" disabled>
        {"CREATED By A VERMONT CCV IT STUDENT "}<br/>
        {"A SIMPLE WEBPAGE TO MY HOME SERVER"}<br/>
        {"TO EASILY NAVIGATE THROUGH THE SERVER"}<br/>
        {"ONLY ADMIN AND HOME USERS HAVE ACCESS"}<br/>    
        </Button></DialogTitle>

            <List sx={{ pt: 0 ,padding:'10px' , margin:'10px' ,borderStyle:'double'}}>
            <DialogTitle><Button variant="outlined" disabled>EMAIL ADDRESSES</Button></DialogTitle>
            
            <Typography>{"info@unsortedserver.com"}</Typography>
            <Typography>{"admin@unsortedserver.com"}</Typography>

            <DialogTitle><Button variant="outlined" disabled>TEXT PHONE NUMBER</Button></DialogTitle>

            <Typography>{"COMING SOON"}</Typography>
            <Typography>{"COMING SOON"}</Typography>
            </List>
            <Button sx={{margin:'5px'}}variant="contained" onClick={handleClose}>CLOSE</Button>
        </Dialog>
    </CardActions>
    </Card>
  );
}