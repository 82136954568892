import UCard from "./UCard";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import UCardAdmin from "./UCardAdmin";


function App() {
  let contactImage ="https://unsortedpage.com/contact.png"
  let cameras = "https://unsortedpage.com/cameras.jpg"
  let databaseImage = "https://unsortedpage.com/database.jpg"
  let vncImage ="https://unsortedpage.com/vnc.png"
  let proxmoxImage ="https://unsortedpage.com/proxmox.JPG"
  return (
    <div className="App" style={{marginTop:'20%' , marginBottom:'20%'}}>
      <Box sx={{ width: '100%' }}>
      <Stack spacing={5}>
        <UCardAdmin image = {contactImage} />
        <UCard image = {proxmoxImage} title ={'PROXMOX DASHBOARD'} content ={'ADMIN DASHBOARD INTERFACE'}/>
        <UCard image = {databaseImage} title ={'SERVER DATABASE'} content ={'QUICK ACCESS TO THE DATABASE'}/>
        <UCard image = {cameras} title ={'CAMERAS SYSTEM'} content ={'QUICK ACCESS TO THE NVR SERVER'}/>
        <UCard image = {vncImage} title ={'VNC'} content ={'QUICK ACCESS TO REMOTE DESKTOP '}/>

       </Stack>
    </Box>
    
    </div>
  );
}

export default App;
